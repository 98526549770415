<template>

    <div
        ref="flash"
        id="flash"
        class="toast"
        :class="flashClass"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
    >
        <div class="d-flex">
            <div class="toast-body">

                <p>{{ flash.time }}</p>
                <template v-if="flash.content">

                    <p v-if="flash.content.message">
                        {{ flash.content.message }}
                    </p>

                    <ul v-if="flash.content.errors">
                        <template v-for="(errors, field) in flash.content.errors">

                            <li v-for="error in errors" :key="error">
                                {{ field }} : {{ error }}
                            </li>

                        </template>
                    </ul>
                </template>

            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>

</template>

<script>
import {Toast} from 'bootstrap'

export default {
    name: 'ComponentFlash',

    data() {
        return {
            toast: null
        }
    },
    
    computed: {
        flash() {
            return this.$store.state.flash
        },
    
        flashClass() {
            if (this.flash.type === 'danger') {
                return 'bg-danger'
            } else if (this.flash.type === 'success') {
                return 'bg-success'
            } else if (this.flash.type === 'warning') {
                return 'bg-warning'
            } else {
                return 'bg-info'
            }
        }
    },
    
    watch: {
        flash() {
            if (this.flash !== false) {
                this.show()
            }
        }
    },

    methods: {
        show() {
            setTimeout(() => {
                this.toast.show()
            }, 1)
        },

        init() {
            this.toast = Toast.getOrCreateInstance(this.$refs.flash, {
                animation: true,
                autohide: true,
                delay: 5000
            })
        }
    },
    
    mounted() {
        this.init()
    }
}
</script>
