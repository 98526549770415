
import Vue from 'vue';
import Vuex from 'vuex';
import dayjs from 'dayjs';
import axios from 'axios';
import _ from 'lodash';

import menu from './menu';

Vue.use(Vuex);

const store = new Vuex.Store({

    state: {
        ...menu,
        odds_type: {
            'odds_fr_1': {
                'value': 'odds_fr_1',
                'label': 'Esito finale 1',
                'group': 'Esito finale 1X2',
                'group_key': 'fr',
                'slug': '1'
            },
            'odds_fr_2': {
                'value': 'odds_fr_2',
                'label': 'Esito finale 2',
                'group': 'Esito finale 1X2',
                'group_key': 'fr',
                'slug': '2'
            },
            'odds_fr_x': {
                'value': 'odds_fr_x',
                'label': 'Esito finale X',
                'group': 'Esito finale 1X2',
                'group_key': 'fr',
                'slug': 'X'
            },
            'odds_dc_1x': {
                'value': 'odds_dc_1x',
                'label': 'Esito finale 1X',
                'group': 'Double Chance',
                'group_key': 'dc',
                'slug': '1X'
            },
            'odds_dc_x2': {
                'value': 'odds_dc_x2',
                'label': 'Esito finale X2',
                'group': 'Double Chance',
                'group_key': 'dc',
                'slug': 'X2'
            },
            'odds_dc_12': {
                'value': 'odds_dc_12',
                'label': 'Esito finale 12',
                'group': 'Double Chance',
                'group_key': 'dc',
                'slug': '12'
            },
            'odds_gou_over25': {
                'value': 'odds_gou_over25',
                'label': 'Over 2.5',
                'group': 'Under / Over 2.5',
                'group_key': 'gou',
                'slug': 'O2,5'
            },
            'odds_gou_under25': {
                'value': 'odds_gou_under25',
                'label': 'Under 2.5',
                'group': 'Under / Over 2.5',
                'group_key': 'gou',
                'slug': 'U2,5'
            },
            'odds_btts_yes': {
                'value': 'odds_btts_yes',
                'label': 'Goal',
                'group': 'Goal / NoGoal',
                'group_key': 'btts',
                'slug': 'G'
            },
            'odds_btts_no': {
                'value': 'odds_btts_no',
                'label': 'NoGoal',
                'group': 'Goal / NoGoal',
                'group_key': 'btts',
                'slug': 'NG'
            }
        },
        user: null,
        flash: false,
        bill: [],
        bookmarks: [],
        notifications: false,
    },

    mutations: {
        setUserData (state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
            axios.defaults.headers.common.Authorization = `Bearer ${user.token}`
        },

        clearUserData () {
            const user = JSON.parse(localStorage.getItem('user'));
            if( user ){
                axios.post(
                    '/auth/logout',
                    { },
                    {
                        headers: {
                            common: {
                                'Authorization': `Bearer ${user.token}`
                            }
                        }
                    }
                );
            }

            localStorage.removeItem('user');
            window.location.href = '/';
        },

        setFlash(state, flash){
            state.flash = flash;
        },

        // Bill
        setBillData (state, bill) {
            state.bill = bill;
        },

        async addEventToBill(state, event){
            console.log('aggiungo evento', event);

            // se esiste gia lo cancello prima
            await this.commit('removeEventToBill', event.event.id);

            // aggiungo l'evento
            state.bill.push(event);
            console.log('Aggiunto');

            return;
        },

        async removeEventToBill(state, event_id){
            console.log('rimuovo evento', event_id);

            state.bill.forEach(function(item, index) {
                if(item.event.id == event_id){
                    state.bill.splice(index, 1);
                    console.log('trovato e rimosso')
                }
            });

            return;
        },

        clearBill(state){
            state.bill = [];
        },

        // Bookmarks
        setBookmarksData (state) {
            if(!this.getters.isLoggedIn) return [];

            axios.get('/bookmark/index')
            .then(({data}) => {
                state.bookmarks = data;
            })
            .catch((error) => {
                console.log(error.response.data);
            });
        },

        async addEventToBookmarks(state, event){
            console.log('aggiungo evento ai preferiti', event);

            // se l'evento esiste già lo elimino prima
            await this.commit('removeEventToBookmarks', event.event_id);

            // aggiungo l'evento
            state.bookmarks.push(event);
            console.log('- Aggiunto');
        },

        async removeEventToBookmarks(state, event_id){
            console.log('rimuovo evento dai preferiti', event_id);

            state.bookmarks.forEach(function(item, index) {
                if(item.event_id == event_id){
                    state.bookmarks.splice(index, 1);
                    console.log('- Trovato e rimosso')
                }else{
                    console.warn('- Non presente');
                }
            });

            return true;
        },

        setNotificationsData (state, data) {
            state.notifications = data;
        },
    },

    actions: {
        login ({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/login', credentials)
                .then(({ data }) => {
                    commit('setUserData', data);
                    resolve(data);
                }).catch((error) => {
                    reject(error)
                });
            });
        },

        logout ({ commit }) {
            commit('clearUserData');
        },

        flashSuccess({ commit }, content){
            commit('setFlash', {
                type: 'success',
                time: dayjs().format('DD/MM HH:mm:ss'),
                content: content ? content : { message: 'Success!' }
            });
        },

        flashWarning({ commit }, content){
            commit('setFlash', {
                type: 'warning',
                time: dayjs().format('DD/MM HH:mm:ss'),
                content: content ? content : { message: 'Warning!' }
            });
        },

        flashDanger({ commit }, content){
            console.log(content);
            commit('setFlash', {
                type: 'danger',
                time: dayjs().format('DD/MM HH:mm:ss'),
                content: content ? content : { message: 'Error!' }
            });
        },

        flashOut(){
            commit('setFlash', false);
        },       

        async getNotifications({ commit }) {
            const response = await axios.get('/notification/index');
            commit('setNotificationsData', response.data);            
        },

        clearBill({ commit }){
            commit('clearBill');
        },
    },

    getters : {
        isLoggedIn: state => !!state.user,
        user_id: state => state.user.user.id,
        user: state => state.user,
        usergroup: state => state.user.user.usergroup.slug,
        bill: state => state.bill,
        odds_type: state => state.odds_type,
        bookmarks: state => {
            var b = state.bookmarks;
            b = _.mapKeys(b, 'event_id');
            b = _.mapValues(b, 'odds_type');
            return b;
        },

        odds_label: (state) => (k) => state.odds_type[ k ] ? state.odds_type[ k ].label : k,
        odds_slug: (state) => (k) => state.odds_type[ k ] ? state.odds_type[ k ].slug : k,

        notifications: state => state.notifications,
    }
});

store.watch(
    (state) => {
        return state.bill
    },
    (value) => {
        console.log('Store watch -> Bill');
        localStorage.setItem('bill', JSON.stringify(value));
    }
)

store.watch(
    (state) => {
        return state.bookmarks
    },
    (value) => {
        console.log('Store watch -> Bookmarks');

        axios.post('/bookmark/sync', {events: value})
        .then((success) => {
            console.log('Success', success);
        })
        .catch((error) => {
            console.warn('Error', error);
        });
    }
)

export default store
