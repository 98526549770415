<template>
    <footer id="footer" class="d-print-none">
        <div class="container">

            <div class="row justify-content-between">
                <div class="col-lg-auto">

                    <ul class="list-inline">
                        <li class="list-inline-item">
                            Zikibet S.r.l.
                        </li>
                        <li class="list-inline-item">
                            C.so Della Repubblica, 109 - 04100, Latina (IT)
                        </li>
                        <li class="list-inline-item">
                            P.I. 03055430593
                        </li>
                        <li class="list-inline-item">
                            &copy; 2022, Tutti i diritti riservati
                        </li>
                    </ul>

                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <a href="https://www.iubenda.com/privacy-policy/74339111" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
                        </li>
                        <li class="list-inline-item">
                            <router-link :to="{ name: 'public.infos' }">
                                Informazioni
                            </router-link>
                        </li>
                        <li class="list-inline-item">
                            <router-link :to="{ name: 'public.terms' }">
                                Termini di utilizzo
                            </router-link>
                        </li>
                        <li class="list-inline-item">
                            <router-link :to="{ name: 'public.privacy' }">
                                Privacy politicy
                            </router-link>
                        </li>
                        <li class="list-inline-item">
                            <router-link :to="{ name: 'public.truffe' }">
                                Evita la truffa
                            </router-link>
                        </li>
                        <li class="list-inline-item">
                            <router-link :to="{ name: 'public.contattaci' }">
                                Contattaci
                            </router-link>
                        </li>
                    </ul>

                </div>
                <div class="col-lg-auto">

                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <img src="/images/18.png"/>
                        </li>
                        <li class="list-inline-item">
                            <img src="/images/adm.png"/>
                        </li>
                    </ul>

                </div>
            </div>

        </div>
    </footer>
</template>

<script>
(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);

export default {
    name: "ComponentZikiFooter"
};
</script>
