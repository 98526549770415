<template>

    <div data-bs-theme="dark">
        <Flash/>
        <router-view :key="$route.fullPath"></router-view>
    </div>

</template>

<script>
export default {
    name: 'AppMain'
}
</script>
