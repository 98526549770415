<template>
    <nav>

        <div v-if="unreadMessages && unreadMessages.length>0">
            <div class="d-grid gap-3">

                <Message :own="true" :shortMessage="true" :readAll="true" :message="message.messages" v-for="(message, index) in unreadMessages" :key="index" class="mt-0" />               

            </div>
        </div>
        <div v-else class="alert alert-info my-2">
            Nessun messaggio nuovo
        </div>

        <router-link class="btn btn-outline-primary d-block w-100 mt-3" :to="{ name: 'messages.index' }">
            Vedi tutti i messaggi
        </router-link>

    </nav>
</template>

<script>

import Message from '@/components/Message'

export default {
    name: 'MessagesTab',

    components: {
        Message
    },

    data() {
        return {
            unreadMessages: null,
        }
    },

    methods: {

        getUnreadMessages() {
            axios.get('/conversation/unread').then(response => {
                this.unreadMessages = response.data;
            });
        },

    },

    created() {
        this.getUnreadMessages();
    },
}

</script>
