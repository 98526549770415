<template>
    <div>
        <i class="fas fa-spinner fa-pulse text-primary"></i>
        <span class="ms-2">Caricamento in corso...</span>
    </div>
</template>

<script>
export default {
    name: 'ComponentLoading'
}
</script>
