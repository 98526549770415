<template>
    <div class="card">
        <div class="card-body" :class="{'ownMessage': own}">

            <div v-if="deleteButton" class="position-absolute top-0 start-100 translate-middle">
                <div class="btn btn-sm btn-danger mx-2" @click="deleteMessage()">
                    <i class="fa fa-close"></i>
                </div>
            </div>

            <!-- Show link to user profile or just text username -->
            <UserLink v-if="userLink" :user="message.user" class="d-inline" />
            <b v-else>@{{message.user.username}}</b>
            ha scritto il {{message.created_at | datetime('DD/MM/YYYY HH:mm')}}


            <hr>

            <!-- Show deleted message, shortened, or complete -->
            <template v-if="deleted">
                <i>{{deletedLabel}}</i>
            </template>
            <template v-else-if="shortMessage">
                {{message.body.substr(0, 15)}}{{ (message.body.length > 15) ? '...' : '' }}
            </template>
            <template v-else>
                <span style="white-space: break-spaces;">{{message.body}}</span>
            </template>

            <div v-if="readAll" class="text-end">
                <router-link class="text-right" :to="{name: 'messages.show', params: {id: message.thread_id}}">
                    Leggi tutto
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'Message',

    props: {
        message: Object,
        own: Boolean,
        readAll: Boolean, // Add 'Leggi tutto' link
        shortMessage: Boolean, // Shorten message
        userLink: Boolean, // Show link to user profile or just text username
        deleteButton: Boolean, // Show button to delete message and dispatch event
        deleted: Boolean,
        deletedLabel: String,
    },

    methods: {
        deleteMessage() {
            // Show different confirmation message if you want to delete your own comment or other user's one
            if (this.own) {
                if (!confirm('Sei sicuro di voler cancellare il tuo commento?'))
                    return;
            } else {
                if (!confirm('Sei sicuro di voler cancellare il commento di questo utente?'))
                    return;
            }

            this.$emit('deleteMessage', this.message.id);
        }
    },
}
</script>

<style scoped>

.ownMessage {
    background-color: #383F45;
    border-color: #596066;
}

</style>
