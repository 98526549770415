<template>
    <nav>

        <div v-if="notifications && notifications.length > 0">

            <div class="d-grid gap-3">
                <div v-for="(notification, index) in notifications" :key="index">
                    <Notification :notification="notification" />
                </div>
            </div>

            <router-link class="btn btn-outline-primary d-block w-100 mt-3" :to="{ name: 'user.notification' }">
                Vedi tutte le notifiche
            </router-link>

        </div>
        <div v-else class="alert alert-info">
            Nessuna notifica per il momento...
        </div>

    </nav>
</template>

<script>

import Notification from '@/components/Notification.vue'

export default {
    name: 'NotificationsTab',

    components: {
        Notification
    },

    computed: {
        notifications(){
            return this.$store.getters.notifications.data;
        }
    }
}
</script>
