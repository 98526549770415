<template>
    <div>
        <router-link :class="{'title h4': title}" :to="{name: 'user.view', params: {username: user.username}}">            
            <i class="fas fa-award text-primary me-1" v-if="user.challenge_winner"></i>
            {{user.username}}</router-link>
    </div>
</template>

<script>
export default {
    name: 'UserLink',

    props: {
        user: Object,
        title: Boolean,
    },
}
</script>

<style scoped>

.title {
    color: #fff !important;
}

</style>
