<template>
	<div id="layout-admin" class="px-lg-3">

		<header id="header">
			<div class="container-fluid">

				<div class="row justify-content-between">
					<div class="col-auto">

						<router-link :to="{ name: 'dashboard' }">
							<img class="logo" src="/images/logo_alt.png" alt="Zikipro">
						</router-link>

					</div>
					<div class="col-auto text-end">

						Area di amministrazione

					</div>
				</div>

			</div>
		</header>

		<main id="main">
			<div class="container-fluid">

				<ul class="nav my-3 pb-3 border-bottom">
					<li class="nav-item" v-for="item in menu" :key="item.route">
						<router-link class="nav-link" :to="{name: item.route}">
							{{ item.label }}
						</router-link>
					</li>
				</ul>

				<div v-if="$slots.title">
					<slot name="title" />
				</div>

				<div class="py-5">
                    <slot />
                </div>

			</div>
		</main>

		<ZikiFooter />

	</div>
</template>

<script>
export default {
	name: "LayoutAdmin",

	data(){
		return {
			menu: [
				{
					label: 'Blackboard',
					route: 'admin.dashboard'
				},
				{
					label: 'Utenti',
					route: 'admin.user.index'
				},
				{
					label: 'Campionati',
					route: 'admin.league.index'
				},
				{
					label: 'Sfide',
					route: 'admin.challenge.index'
				},
				{
					label: 'Bollette',
					route: 'admin.bill.index'
				},
				{
					label: 'Squadre',
					route: 'admin.team.elo'
				},
				{
					label: 'Eventi ELO',
					route: 'admin.event.elo'
				},
				{
					label: 'Eventi duplicati',
					route: 'admin.event.duplicates'
				},
				{
					label: 'Eventi incompleti',
					route: 'admin.event.incomplete'
				}
			]
		}
	}
};
</script>
