<template>
    <div class="card">

        <!-- Accept friend request -->
        <friend-request v-if="notification.type=='FriendRequest'" :user="{id: notification.data.sender_id, username: info.username}" />

        <!-- Rest of notifications -->
        <div class="card-body position-relative" v-else>

            <div class="row align-items-center mb-3">
                
                    <!-- If username is present, show component with link to user profile -->
                    <user-link v-if="info.username" :user="{username: info.username}" class="d-inline" />

                    <p v-html="info.text" class="d-inline"></p>
                
            </div>
            <div class="row align-items-center">
                <div class="col-12">

                    <router-link
                    v-if="info.link"
                    :to="info.link"
                    class="btn btn-sm btn-primary w-100"
                    >
                        <i class="fa-solid fa-magnifying-glass"></i>
                        Più informazioni
                    </router-link>

                </div>
            </div>

            <span v-if="!notification.read_at" class="position-absolute top-0 start-100 translate-middle p-2 bg-danger rounded-circle">
                <span class="visually-hidden">Nuova notifica</span>
            </span>

        </div>

    </div>
</template>

<script>

import FriendRequest from '@/components/FriendRequest'

export default {
    name: 'Notification',

    components: {FriendRequest},

    props: {
        notification: Object,
    },

    computed: {

        info() {
            switch(this.notification.type) {
                case 'BillCanceled':
                    return this.billCanceled();
                case 'BillClosed':
                    return this.billClosed();
                case 'ChallengeClosed':
                    return this.challengeClosed();
                case 'EventCanceled':
                    return this.eventCanceled();
                case 'LevelUp':
                    return this.levelUp();
                case 'FriendRequest':
                    return this.friendRequest();
                case 'RequestAccepted':
                    return this.requestAccepted();
                case 'GroupCreated':
                    return this.groupCreated();
                case 'EventDateInvalid':
                    return this.eventDateInvalid();
            }
        },

    },

    methods: {

        billCanceled() {
            return {
                text: `La bolletta #${this.notification.data.bill_id}
                    creata il ${ this.$options.filters.datetime(this.notification.data.start_at, ('DD/MM/YYYY'))}
                    non può partecipare alla sfida`,
                link: {
                    name: 'bill.show',
                    params: {
                        id: this.notification.data.bill_id
                    },
                }
            };
        },

        billClosed() {
            let result = (this.notification.data.is_winning) ? '<span class="text-success">vincente</span> ' : '<span class="text-danger">perdente</span>';

            return {
                text: `La bolletta #${this.notification.data.bill_id} è stata chiusa con il seguente risultato: ${result}.`,
                link: {
                    name: 'bill.show',
                    params: {
                        id: this.notification.data.bill_id
                    },
                }
            };
        },

        challengeClosed() {
            return {
                text: `La sfida #${this.notification.data.number} si è conclusa!`,
                link: {
                    name: 'challenge.show',
                    params: {
                        id: this.notification.data.challenge_id
                    },
                }
            }
        },

        eventCanceled() {
            return {
                text: `L'evento
                    tra <b>${this.notification.data.team_home}</b>
                    e <b>${this.notification.data.team_away}</b>
                    del ${ this.$options.filters.datetime(this.notification.data.start_at, ('DD/MM/YYYY'))}
                    che si trova nella tua bolletta <b>#${this.notification.data.bill_id}</b>
                    è stato annullato.`,
                link: {
                    name: 'bill.show',
                    params: {
                        id: this.notification.data.bill_id
                    },
                }
            };
        },

        levelUp() {
            return {
                text: `Dopo i risultati dell'ultima sfida, sei stato promosso di livello.`,
                link: {
                    name: 'challenge.ranking',
                    params: false,
                }
            };
        },

        friendRequest() {
            return {
                // Username to show component with link to profile
                username: this.notification.data.username,
                text: ` ti ha inviato una richiesta di amicizia.`,
                link: {
                    name: 'user.view',
                    params: {
                        username: this.notification.data.username,
                    },
                }
            };
        },

        requestAccepted() {
            return {
                // Username to show component with link to profile
                username: this.notification.data.username,
                text: ` ha accettato la tua richiesta di amicizia.`,
                link: {
                    name: 'user.view',
                    params: {
                        username: this.notification.data.username,
                    },
                }
            };
        },

        // Groups
        groupCreated() {
            return {
                // Username to show component with link to profile
                username: this.notification.data.username,
                text: ` ti ha invitato al gruppo "${this.notification.data.group_name}"`,
                link: {
                    name: 'group.show',
                    params: {
                        id: this.notification.data.bill_id,
                    },
                }
            };
        },

        eventDateInvalid() {
            return {
                text: `Un evento è stato rimosso dalla tua bolletta perché non può partecipare alla sfida`,
                link: {
                    name: 'bill.show',
                    params: {
                        id: this.notification.data.bill_id,
                    },
                }
            };
        },

    },
}
</script>
