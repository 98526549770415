import Vue from 'vue'
import VueRouter from 'vue-router'

import public_routes from './public'
import backend_routes from './backend'
import admin_routes from './admin'

Vue.use(VueRouter)

const routes = [
    ...public_routes,
    ...backend_routes,
    ...admin_routes
]

const router = new VueRouter({
    routes: routes,
    // mode: 'hash'
});

router.beforeEach((to, from, next) => {
    // controllo l'utente loggato
    const loggedIn = JSON.parse(localStorage.getItem('user'));

    if (to.meta.auth && !loggedIn) {
        next({
            name: 'login',
            query: { error: 'unauthorized' }
        })
        return
    }

    if (loggedIn) {
        const group = loggedIn.user.usergroup.slug;

        if (to.meta.groups) {
            if(!to.meta.groups.includes(group) ){
                localStorage.removeItem('user');

                next({
                    name: 'login',
                    query: { error: 'notingroup' }
                })
                return
            }
        }
    }

    next()
});

export default router
