<template>


    <div class="row row-cols-3 g-3">
        <div>
            <a class="btn btn-outline-primary w-100 px-1 py-3" :href="`mailto:example@email.com?subject=Entra a far parte della community Zikipro&body=${message} ${link}`" target="_blank">
                <i class="fas bg-success fa-envelope fa-xl"></i>
                <div>Email</div>
            </a>
        </div>

        <div>
            <a class="btn btn-outline-primary w-100 px-1 py-3" :href="'sms:&body='+message+' '+link" target="_blank">
                <i class="fa fa-comment-sms fa-xl"></i>
                <div>SMS</div>
            </a>
        </div>

        <div>
            <a class="btn btn-outline-primary w-100 px-1 py-3" :href="'https://wa.me/?text='+message+': '+link" target="_blank">
                <i class="fa-brands fa-whatsapp fa-xl"></i>
                <div>Whatsapp</div>
            </a>
        </div>

        <div>
            <a class="btn btn-outline-primary w-100 px-1 py-3" href="http://www.facebook.com/share.php?u=https://zikibet.app/" target="_blank">
                <i class="fa-brands fa-facebook fa-xl"></i>
                <div>Facebook</div>
            </a>
        </div>

        <div>
            <a class="btn btn-outline-primary w-100 px-1 py-3" :href="`https://twitter.com/intent/tweet?text=${message}&url=${link}`" target="_blank">
                <i class="fa-brands fa-twitter fa-xl"></i>
                <div>Twitter</div>
            </a>
        </div>

        <div>
            <a class="btn btn-outline-primary w-100 px-1 py-3" :href="`https://t.me/share/url?text=${message}&url=${link}`" target="_blank">
                <i class="fa-brands fa-telegram fa-xl"></i>
                <div>Telegram</div>
            </a>
        </div>
    </div>

</template>

<script>

export default {
    name: 'ShareTab',

    data() {
        return {
            message: "Registrati per accedere ai contenuti di Zikipro utilizzando il mio Codice Amico: " + this.$store.getters.user.user.referal_code,
        }
    },

    computed: {
        link() {
            return 'https://www.zikibet.app/#/registration?referal=' + this.$store.getters.user.user.referal_code;
        }
    }
}
</script>
