export default [
    // Dashboard
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/pages/backend/Dashboard.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },

    // Events
    {
        path: '/event',
        name: 'event.index',
        component: () => import('@/pages/backend/event/Index.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/event/classic',
        name: 'event.classic',
        component: () => import('@/pages/backend/event/Classic.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },

    // Bills
    {
        path: '/bill',
        name: 'bill.index',
        component: () => import('@/pages/backend/bill/Index.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/bill/create',
        name: 'bill.create',
        component: () => import('@/pages/backend/bill/Create.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/bill/show/:id',
        name: 'bill.show',
        component: () => import('@/pages/backend/bill/Show.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },

    // Bookmarks
    {
        path: '/bookmark',
        name: 'bookmark.index',
        component: () => import('@/pages/backend/bookmark/Index.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },

    // Scores
    {
        path: '/score/index',
        name: 'score.index',
        component: () => import('@/pages/backend/score/Index.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/score/community',
        name: 'score.community',
        component: () => import('@/pages/backend/score/Community.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/score/weekly',
        name: 'score.weekly',
        component: () => import('@/pages/backend/score/Weekly.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/score/general',
        name: 'score.general',
        component: () => import('@/pages/backend/score/General.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/score/premium',
        name: 'score.premium',
        component: () => import('@/pages/backend/score/Premium.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/score/zikibet',
        name: 'score.zikibet',
        component: () => import('@/pages/backend/score/Zikibet.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/score/ready',
        name: 'score.ready',
        component: () => import('@/pages/backend/score/Ready.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },

    // Challenge
    {
        path: '/challenge/list',
        name: 'challenge.list',
        component: () => import('@/pages/backend/challenge/List.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    // {
    //     path: '/challenge/active',
    //     name: 'challenge.active',
    //     component: () => import('@/pages/backend/challenge/Active.vue'),
    //     meta: {
    //         auth: true,
    //         groups: false
    //     }
    // },
    // {
    //     path: '/challenge/closed',
    //     name: 'challenge.closed',
    //     component: () => import('@/pages/backend/challenge/Closed.vue'),
    //     meta: {
    //         auth: true,
    //         groups: false
    //     }
    // },
    {
        path: '/challenge/ranking',
        name: 'challenge.ranking',
        component: () => import('@/pages/backend/challenge/Ranking.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/challenge/come-si-guadagna',
        name: 'challenge.howto',
        component: () => import('@/pages/backend/challenge/HowTo.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/challenge/show/:id',
        name: 'challenge.show',
        component: () => import('@/pages/backend/challenge/Show.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/user/profile',
        name: 'user.profile',
        component: () => import('@/pages/user/Profile.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/user/notification',
        name: 'user.notification',
        component: () => import('@/pages/user/Notification.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },

    // Sistemi salva errori
    {
        path: '/salvaerrori/index',
        name: 'system.index',
        component: () => import('@/pages/backend/system/Index.vue'),
    },
    {
        path: '/salvaerrori/show/:events/:strategy',
        name: 'system.show',
        component: () => import('@/pages/backend/system/Show.vue'),
    },
    // Community
    {
        path: '/u/:username',
        name: 'user.view',
        component: () => import('@/pages/user/View.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },

    // Show conversations
    {
        path: '/messages/index',
        name: 'messages.index',
        component: () => import('@/pages/backend/messages/Index.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    // Show single conversation
    {
        path: '/messages/:id',
        name: 'messages.show',
        component: () => import('@/pages/backend/messages/Show.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    // New message
    {
        path: '/messages/new/:username',
        name: 'messages.new',
        component: () => import('@/pages/backend/messages/Create.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/community/bills',
        name: 'community.bills',
        component: () => import('@/pages/backend/community/FriendsBills.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/community/friends',
        name: 'community.friends',
        component: () => import('@/pages/backend/community/Friends.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/community/search',
        name: 'community.search',
        component: () => import('@/pages/backend/community/SearchFriends.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/group/create/:id',
        name: 'group.create',
        component: () => import('@/pages/backend/group/Create.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/group/show/:id',
        name: 'group.show',
        component: () => import('@/pages/backend/group/Show.vue'),
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/group/index',
        name: 'group.index',
        component: () => import('@/pages/backend/group/Index.vue'),
        props: {
            ownGroups: false,
        },
        meta: {
            auth: true,
            groups: false
        }
    },
    {
        path: '/group/index/me',
        name: 'group.index.me',
        component: () => import('@/pages/backend/group/Index.vue'),
        props: {
            ownGroups: true,
        },
        meta: {
            auth: true,
            groups: false,
        }
    },
]
