export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages/public/Home.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/informazioni',
        name: 'public.infos',
        component: () => import('@/pages/public/Infos.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/termini-condizioni',
        name: 'public.terms',
        component: () => import('@/pages/public/Terms.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/privacy-politicy',
        name: 'public.privacy',
        component: () => import('@/pages/public/Privacy.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/evita-la-truffa',
        name: 'public.truffe',
        component: () => import('@/pages/public/Truffe.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/contattaci',
        name: 'public.contattaci',
        component: () => import('@/pages/public/Contattaci.vue'),
        meta: {
            groups: false
        }
    },

    // Auth
    {
        path: '/registration',
        name: 'registration',
        component: () => import('@/pages/auth/Registration.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/auth/Login.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/recover',
        name: 'recover',
        component: () => import('@/pages/auth/Recover.vue'),
        meta: {
            groups: false
        }
    },
    {
        path: '/reset',
        name: 'reset',
        component: () => import('@/pages/auth/Reset.vue'),
        meta: {
            groups: false
        }
    }
]
