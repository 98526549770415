<template>
    <nav id="menu">
        <ul>
            <!-- dashboard -->
            <li>
                <router-link :to="{ name: 'dashboard' }">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    <span>Blackboard</span>
                </router-link>
            </li>

            <!-- menu -->
            <li v-for="item in menu" :key="item.section">
                <div>
                    <i class="fa-fw" :class="item.icon"></i>
                    <span>{{ item.section }}</span>
                </div>

                <ul v-if="item.submenu">
                    <li v-for="child in item.submenu" :key="child.label">
                        <router-link :to="{ name: child.route }">
                            <span>{{ child.label }}</span>
                        </router-link>
                    </li>
                </ul>
            </li>

            <!-- profilo -->
            <li>
                <div>
                    <i class="fas fa-fw fa-user-astronaut"></i>
                    <span>Profilo personale</span>
                </div>

                <ul>
                    <li>
                        <router-link :to="{ name: 'user.profile' }">
                            <span>Informazioni di profilo</span>
                        </router-link>
                    </li>
                    <li>
                        <button class="logout" type="button" @click="$root.logout">
                            <span>Logout</span>
                        </button>
                    </li>
                </ul>
            </li>
        </ul>

        <div v-if="$store.getters.usergroup == 'admin'">
            <hr>

            <router-link :to="{ name: 'admin.dashboard' }" class="btn btn-outline-primary w-100">
                Area di amministrazione
            </router-link>
        </div>

    </nav>
</template>

<script>
export default {
    name: 'LayoutsPartsSidebar',

    computed: {
        menu(){
            return _.filter(this.$store.state.menu, 'submenu');
        }
    }
}
</script>
