if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js');
    });
}

require('./bootstrap');

dayjs.locale('it');

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

import Vue from 'vue';
import AppMain from './app/Main.vue';
import store from './store';
import router from './router';
import dayjs from 'dayjs';
import axios from 'axios';

import LayoutGuest from '@/layouts/Guest.vue';
Vue.component('LayoutGuest', LayoutGuest);

import LayoutBackend from '@/layouts/Backend.vue';
Vue.component('LayoutBackend', LayoutBackend);

import LayoutAdmin from '@/layouts/Admin.vue';
Vue.component('LayoutAdmin', LayoutAdmin);

import Loading from '@/components/Loading.vue';
Vue.component('Loading', Loading);

import ZikiFooter from '@/components/ZikiFooter.vue';
Vue.component('ZikiFooter', ZikiFooter);

import Flash from '@/components/Flash.vue';
Vue.component('Flash', Flash);

import Pagination from '@/components/Pagination'
Vue.component('Pagination', Pagination);

import UserLink from '@/components/UserLink'
Vue.component('UserLink', UserLink);

Vue.filter('datetime', function (value, format) {
    return dayjs(value).utc().format(format);
});

Vue.filter('quote', function (value) {
    if (!value) value = 0;
    value = (Math.round(value * 100) / 100).toFixed(2);
    return value;
});

Vue.filter('eventsValid', function (value) {
    if (!value) return 0;
    if (value.length==0) return 0;
    if ('event' in value[0]) return value.filter(el => !el.event.is_canceled).length;
    return value.filter(el => !el.is_canceled).length;
});

Vue.filter('eventsWinner', function (value) {
    if (!value) return 0;
    if (value.length==0) return 0;
    if ('pivot' in value[0]) return value.filter(el => el.pivot.is_winner).length;
    return value.filter(el => el.is_winner).length;
});

Vue.filter('percentage', function (value, nullable) {
    if (!value && nullable) return '-';
    if (!value) value = 0;

    value = (value * 100).toFixed(2);
    return value+' %';
});

if (process.env.APP_ENV === 'development') {
    Vue.config.devtools = true;
    Vue.config.productionTip = false;
 }

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    computed:{
        config() {
            return {
                registration: process.env.MIX_APP_REGISTRATION == 'false' ? false : true,
            }
        }
    },
    methods: {
        logout() {
            if( !confirm('Sei sicuro di voler uscire?')) return;
            this.$store.dispatch('logout');
        }
    },
    created() {
        const userInfo = localStorage.getItem('user')
        if (userInfo) {
            const userData = JSON.parse(userInfo);
            this.$store.commit('setUserData', userData);

            var update = dayjs(userData.info_updated_at)
            var timeFrom = dayjs().diff(update, 'hours', true);
            console.log('Ultimo aggiornamento info utente ore:', timeFrom);

            if(timeFrom > 2){
                console.debug('Aggiorno le info utente');

                axios.get('/user/me')
                .then(({data}) => {
                    console.log(data);

                    if(!data.is_enabled){
                        console.error('Utente disabilitato')
                        this.$store.dispatch('logout');
                        return;
                    }

                    userData.user = data;
                    userData.info_updated_at = dayjs().format();
                    this.$store.commit('setUserData', userData);
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
            }
        }

        const billInfo = localStorage.getItem('bill')
        if (billInfo) {
            const billData = JSON.parse(billInfo)
            this.$store.commit('setBillData', billData)
        }

        axios.interceptors.response.use(
            response => response,
            error => {
                // ref. https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#client_error_responses
                if (error.response.status === 401) {
                    console.log('Axios interceptors: una risorsa ha riposto 401');
                    this.$store.dispatch('logout')
                    // router.push({
                    //     name: 'user.profile',
                    //     query: { error: 'trial_expired' }
                    // });
                }
                return Promise.reject(error)
            }
        )
    },
    mounted() {
        this.$store.commit('setBookmarksData')
    },
    render: h => h(AppMain),
}).$mount('#app');
