<template>
    <div class="card">
        <div class="card-body position-relative">

            <!-- Display username and link to profile -->
            <div class="row align-items-center mb-3">
                <div class="col-12">
                    <user-link :user="{username: user.username}" class="d-inline" /> ti ha inviato una richiesta di amicizia.
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-12">

                    <!-- Accept or reject buttons -->
                    <div class="row">
                        <div class="col-6">
                            <div class="btn btn-sm btn-success w-100" @click="acceptFriend(user.id)">
                                <i class="fa-solid fa-user-plus"></i> Accetta
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="btn btn-sm btn-danger w-100" @click="rejectFriend(user.id)">
                                <i class="fa-solid fa-user-xmark"></i> Rifiuta
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div> <!-- End card body -->
    </div>
</template>

<script>

export default {
    name: 'FriendRequest',

    props: {
        user: Object,
    },

    methods: {
        acceptFriend(id) {
            axios.post('/user/accept/'+id)
            .then(({data}) => {
                // Remove from DOM
                this.$el.parentNode.removeChild(this.$el);
                this.$store.dispatch('flashSuccess', {message: 'Richiesta accettata'});
            })
            .catch((error) => {
                this.$store.dispatch('flashDanger', error.response.data)
                console.log(error.response.data);
            })
        },

        rejectFriend(id) {
            axios.post('/user/reject/'+id)
            .then(({data}) => {
                // Remove from DOM
                this.$el.parentNode.removeChild(this.$el);
                this.$store.dispatch('flashSuccess', {message: 'Richiesta rifiutata'});
            })
            .catch((error) => {
                this.$store.dispatch('flashDanger', error.response.data)
                console.log(error.response.data);
            })
        },
    },
}
</script>
