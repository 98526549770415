<template>
	<div id="layout-backend">

		<div
		class="offcanvas offcanvas-end d-print-none"
		tabindex="-1"
		id="sidebar"
		aria-labelledby="sidebar"
		data-bs-scroll="true"
		data-bs-backdrop="true"
		>
			<div class="offcanvas-header">

				<h5 class="offcanvas-title" id="sidebar-title">
					<router-link :to="{ name: 'dashboard' }"><img class="logo" src="/images/logo_alt.png" alt="Zikipro"></router-link>
				</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>

			</div>
			<div class="offcanvas-body">

				<Sidebar />

			</div>
		</div>

		<!-- Share modal -->
		<div class="modal" id="shareModal" tabindex="-1">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Condividi</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<ShareModal />
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" data-bs-dismiss="modal">Chiudi</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Messages -->
		<div
			class="offcanvas offcanvas-end d-print-none"
			tabindex="-1"
			id="messagesTab"
			ref="messagesTab"
			aria-labelledby="messagesTab"
			data-bs-scroll="true"
			data-bs-backdrop="true"
		>
			<div class="offcanvas-header">

				<h5 class="offcanvas-title" id="sidebar-title">
					Messaggi
				</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>

			</div>
			<div class="offcanvas-body">

				<MessagesTab />

			</div>
		</div>

        <!-- Notifications -->
		<div
		class="offcanvas offcanvas-end d-print-none"
		tabindex="-1"
		id="notificationsTab"
		ref="notificationsTab"
		aria-labelledby="notificationsTab"
		data-bs-scroll="true"
		data-bs-backdrop="true"
		>
			<div class="offcanvas-header">

				<h5 class="offcanvas-title" id="sidebar-title">
					Notifiche
				</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>

			</div>
			<div class="offcanvas-body">

				<NotificationsTab v-if="!loadingNotifications" />
				<loading v-else />

			</div>
		</div>

		<header id="header" class="d-print-none">
			<div class="container">

				<div class="row justify-content-between">
					<div class="col-auto">

						<router-link :to="{ name: 'dashboard' }">
							<img class="logo d-none d-lg-inline" src="/images/logo_alt.png" alt="Zikipro">
							<img class="logo d-lg-none" src="/images/logo_sm_alt.png" alt="Zikipro">
						</router-link>

					</div>
					<div class="col-auto text-end">

						<ul class="list-inline m-0">
                            <li class="list-inline-item" v-if="$store.getters.user.user.usergroup_id == 3">

                                <button
                                class="btn btn-outline-info"
                                type="button"
                                @click="$root.logout"
                                >
                                    <i class="fas fa-fw fa-ghost"></i>
                                </button>

                            </li>
							<li class="list-inline-item" v-if="$store.getters.bill.length > 0">

								<router-link
                                class="btn btn-secondary position-relative"
                                :to="{ name: 'bill.create' }"
                                >
									<i class="far fa-fw fa-list-alt"></i>
									<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
										{{ $store.getters.bill.length }}
									</span>
								</router-link>

							</li>

                            <!-- Share button -->
							<li class="list-inline-item">
								<button
									class="btn btn-primary position-relative"
									type="button"
									data-bs-toggle="modal" data-bs-target="#shareModal"
                                >
									<i class="fa fa-fw fa-share-alt"></i>
								</button>
							</li>

							<!-- Messages -->
							<li class="list-inline-item">
								<button
                                class="btn btn-primary position-relative"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#messagesTab"
                                aria-controls="messagesTab"
                                >
									<i class="fa fa-fw fa-message"></i>
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger p-2" v-if="hasUnreadMessages">
                                        <span class="visually-hidden">Nuovi messaggi</span>
                                    </span>
								</button>
							</li>

                            <!-- Notifications -->
							<li class="list-inline-item">
								<button
                                class="btn btn-primary position-relative"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#notificationsTab"
                                aria-controls="notificationsTab"
                                >
									<i class="fa fa-fw fa-bell"></i>
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger p-2" v-if="hasUnreadNotifications">
                                        <span class="visually-hidden">Nuove notifiche</span>
                                    </span>
								</button>
							</li>

							<li class="list-inline-item">

								<button
                                class="btn btn-primary"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#sidebar"
                                aria-controls="sidebar"
                                >
									<i class="fa fa-fw fa-bars"></i>
								</button>

							</li>
						</ul>

					</div>
				</div>

			</div>
		</header>

        <!-- solo per la stampa -->
        <div class="d-none d-print-block">
            <img class="logo" src="/images/logo.png" alt="Zikipro">
        </div>

		<main id="backend">
			<div class="container">

				<div class="mb-5" v-if="$slots.title">
					<slot name="title" />
				</div>

				<template v-if="current_user">

					<div class="alert alert-danger" v-if="!current_user.is_active && !current_user.is_trial">
						<p><strong>Attenzione!</strong></p>
						<p>
							Il tuo periodo di prova è scaduto il {{current_user.trial_info.trial_expired_at | datetime('DD/MM/YYYY')}}
							e ti mancano ancora <strong>{{current_user.trial_info.refered_remain_count}} amici</strong> da fare iscrivere
							per avere l'accesso a Zikipro free per sempre.
						</p>
						<p>In questo momento non puoi più accedere alle funzionalità di Zikipro.</p>
						<p>Condividi il link col tuo codice <strong>{{current_user.referal_link}}</strong></p>
					</div>

					<div class="alert alert-warning" v-else-if="current_user.is_trial">
						<p><strong>Attenzione!</strong></p>
						<p>
							Il tuo periodo di prova finirà tra <strong>{{current_user.trial_info.trial_expired_in_days}} giorni</strong>
							e ti mancano ancora <strong>{{current_user.trial_info.refered_remain_count}} amici</strong> da fare iscrivere
							per avere l'accesso a Zikipro free per sempre.
						</p>
						<p>Condividi il link col tuo codice <strong>{{current_user.referal_link}}</strong></p>
					</div>

				</template>

				<slot />

			</div>
		</main>

		<ZikiFooter />

	</div>
</template>

<script>
import Sidebar from "@/layouts/Sidebar.vue";
import NotificationsTab from "@/layouts/NotificationsTab.vue";
import MessagesTab from "@/layouts/MessagesTab.vue";
import ShareModal from "@/layouts/ShareModal.vue";

export default {
	name: "LayoutBackend",

	data() {
		return {
			hasUnreadMessages: false,
			hasUnreadNotifications: false,
			loadingNotifications: false,
		}
	},

	components: {
		Sidebar,
		NotificationsTab,
		MessagesTab,
        ShareModal,
	},

	computed:{
		current_user(){
			return this.$store.getters.user.user;
		}
	},

	methods: {

		// Check for new messages
		checkNewMessages() {
			axios.get('/conversation/check')
                .then((response) => {
                    if (response.data) {
						this.hasUnreadMessages = true;
					}
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
		},

		// Check if user has unread notifications
		checkNotifications() {
			axios.get('/notification/check')
                .then((response) => {
                    if (response.data) {
						this.hasUnreadNotifications = true;
					}
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
		},

		getNotifications() {
			if (this.loadingNotifications)
				return;

			// Only get notifications if notifications-array is empty (first time load) or user has unread notifications
			if (this.$store.getters.notifications && !this.hasUnreadNotifications)
				return;

			this.loadingNotifications = true;

			this.$store.dispatch('getNotifications').then(response => {
				this.loadingNotifications = false;
				// Show all notifications as read in front-end
				this.hasUnreadNotifications = false;
			});
		},

	},

	mounted() {

		// Bind notifications tab event in order to fetch last notifications
		this.$refs.notificationsTab.addEventListener('show.bs.offcanvas', e => {
			this.getNotifications();
		});

		// Check if user has unread notifications
		this.checkNotifications();

		// Check if user has unread messages
		this.checkNewMessages();
	},

    // data(){
    //     return {
    //         current_user: false
    //     }
    // },

    // methods: {
    //     getUser(){
    //         axios.get('/user/me')
    //         .then(({data}) => {
    //             this.current_user = data;
    //         })
    //         .catch((error) => {
    //             this.$store.dispatch('flashDanger', error.response.data)
    //             console.log(error.response.data);
    //         })
    //     }
    // },

    // created(){
    //     this.getUser();
    // }
};
</script>
