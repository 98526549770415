export default {
    menu: [
        {
            image: 'partite.jpg',
            icon: 'fas fa-futbol',
            section: 'Scegli partite',
            submenu: [
                {
                    route: 'event.index',
                    label: 'Scelta Zikibet'
                },
                {
                    route: 'event.classic',
                    label: 'Scelta classica'
                }
            ]
        },
        {
            image: 'sfida.jpg',
            icon: 'fas fa-trophy',
            section: 'La sfida',
            submenu: [
                {
                    route: 'challenge.list',
                    label: 'Lista Sfide'
                },
                {
                    route: 'challenge.ranking',
                    label: 'Classifiche Sfide'
                },
                {
                    route: 'challenge.howto',
                    label: 'Come si guadagna'
                }
            ]
        },
        {
            image: 'bolletta.jpg',
            icon: 'far fa-list-alt',
            section: 'La mia bolletta',
            submenu: [
                {
                    route: 'bill.index',
                    label: 'Archivio bollette'
                }
            ]
        },
        {
            image: 'salvaerrori.jpg',
            icon: 'fas fa-shield-alt',
            section: 'Salva errori',
            submenu: [
                {
                    route: 'system.index',
                    label: 'Sistemi salva errori'
                }
            ]
        },
        {
            image: 'note.jpg',
            icon: 'far fa-clipboard',
            section: 'Blocco Note',
            submenu: [
                {
                    route: 'bookmark.index',
                    label: 'Eventi salvati'
                }
            ]
        },
        {
            image: 'pronostici.jpg',
            icon: 'fas fa-chart-line',
            section: 'Pronostici',
            submenu: [
                {
                    route: 'score.index',
                    label: 'Ricerca pronostici'
                },
            ]
        },
        {
            image: 'amici.jpg',
            icon: 'fas fa-user-friends',
            section: 'Community',
            submenu: [
                {
                    route: 'community.search',
                    label: 'Cerca amici'
                },
                {
                    route: 'community.friends',
                    label: 'I miei amici'
                },
                {
                    route: 'community.bills',
                    label: 'Le bollette degli amici'
                },
                {
                    route: 'messages.index',
                    label: 'Messaggi'
                },
            ]
        },
        {
            image: 'gruppi.jpg',
            icon: 'fas fa-users',
            section: 'Gruppi',
            submenu: [
                {
                    route: 'group.index.me',
                    label: 'I miei gruppi'
                },
                {
                    route: 'group.index',
                    label: 'Elenco gruppi'
                },
            ]
        },
    ],
};
