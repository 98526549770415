export default [
    {
        path: '/admin',
        name: 'admin.dashboard',
        component: () => import('@/pages/admin/Dashboard.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },

    {
        path: '/admin/user/index',
        name: 'admin.user.index',
        component: () => import('@/pages/admin/user/Index.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/user/create',
        name: 'admin.user.create',
        component: () => import('@/pages/admin/user/Create.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/user/show/:id',
        name: 'admin.user.show',
        component: () => import('@/pages/admin/user/Show.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        }
    },
    {
        path: '/admin/league/index',
        name: 'admin.league.index',
        component: () => import('@/pages/admin/league/Index.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/league/groups',
        name: 'admin.league.group',
        component: () => import('@/pages/admin/league/Groups.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/league/categories',
        name: 'admin.league.categories',
        component: () => import('@/pages/admin/league/Categories.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/league/links',
        name: 'admin.league.links',
        component: () => import('@/pages/admin/league/Links.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/challenge/index',
        name: 'admin.challenge.index',
        component: () => import('@/pages/admin/challenge/Index.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/challenge/show/:id',
        name: 'admin.challenge.show',
        component: () => import('@/pages/admin/challenge/Show.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/bill/index',
        name: 'admin.bill.index',
        component: () => import('@/pages/admin/bill/Index.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/team/elo',
        name: 'admin.team.elo',
        component: () => import('@/pages/admin/team/Index.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        },
    },
    {
        path: '/admin/bill/show/:id',
        name: 'admin.bill.show',
        component: () => import('@/pages/admin/bill/Show.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        }
    },
    {
        path: '/admin/event/show/:id',
        name: 'admin.event.show',
        component: () => import('@/pages/admin/event/Show.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        }
    },
    {
        path: '/admin/event/elo',
        name: 'admin.event.elo',
        component: () => import('@/pages/admin/event/Elo.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        }
    },
    {
        path: '/admin/event/duplicates',
        name: 'admin.event.duplicates',
        component: () => import('@/pages/admin/event/Duplicates.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        }
    },
    {
        path: '/admin/event/incomplete',
        name: 'admin.event.incomplete',
        component: () => import('@/pages/admin/event/Incomplete.vue'),
        meta: {
            auth: true,
            groups: ['admin']
        }
    }
]
