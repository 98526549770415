<template>
    <nav>
    
        <ul class="pagination justify-content-end">
            <!-- first -->
            <li class="page-item" v-if="links.prev != null">
                <button class="page-link" @click="go(links.first)">
                    <i class="fas fa-fw fa-angle-double-left"></i>
                </button>
            </li>

            <!-- prev -->
            <li class="page-item" v-if="links.prev != null">
                <button class="page-link" @click="go(links.prev)">
                    <i class="fas fa-fw fa-angle-left"></i>
                </button>
            </li>

            <!-- next -->
            <li class="page-item" v-if="links.next != null">
                <button class="page-link" @click="go(links.next)">
                    <i class="fas fa-fw fa-angle-right"></i>
                </button>
            </li>

            <!-- last -->
            <li class="page-item" v-if="links.next != null">
                <button class="page-link" @click="go(links.last)">
                    <i class="fas fa-fw fa-angle-double-right"></i>
                </button>
            </li>
        </ul>

    </nav>
</template>

<script>
export default {
    name: 'ComponentPagination',
    
    props: {
        links: Object,
        action: Function
    },

    methods:{
        go(link){
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.action(link);
        }
    }
}
</script>
