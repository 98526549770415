<template>
	<div id="layout-guest">

        <header id="header">
			<div class="container">

				<div class="row justify-content-between align-items-center">
					<div class="col-auto">

						<router-link :to="{ name: 'home' }">
							<img class="logo" src="/images/logo_alt.png" alt="Zikipro">
						</router-link>

					</div>
					<div class="col-auto text-end">

                        <router-link class="btn btn-primary fw-bold text-uppercase" :to="{ name: 'login' }">Accedi</router-link>

						<!-- <ul class="list-inline m-0">
                            <li class="list-inline-item">
                                <router-link :to="{ name: 'home' }">Home</router-link>
                            </li>
                            <li class="list-inline-item">
                                <router-link :to="{ name: 'login' }">Login</router-link>
                            </li>
                            <template v-if="$root.config.registration">
                                <li class="list-inline-item">
                                    <router-link :to="{ name: 'registration' }">Registration</router-link>
                                </li>
                            </template>
                            <li class="list-inline-item">
                                <router-link :to="{ name: 'recover' }">Recover</router-link>
                            </li>
                        </ul> -->

					</div>
				</div>

			</div>
		</header>

		<main id="main">
            <slot/>
		</main>

        <ZikiFooter />

	</div>
</template>

<script>
export default {
    name: 'LayoutGuest'
}
</script>
